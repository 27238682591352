import React, { useMemo } from "react";
import { baseUrl } from "../../util/apiUtil";

interface Props {
  guid: string;
  size?: "small" | "medium" | "large" | "max";
}
export const Image = React.memo(function Image(props: Props) {
  const url = useMemo(() => {
    if (props.guid) {
      return baseUrl() + "media/" + props.guid;
    }
    return props.size === "small"
      ? "placeholder-small.png"
      : props.size === "medium"
      ? "placeholder-medium.png"
      : props.size === "large"
      ? "placeholder-large.png"
      : "placeholder-medium.png";
  }, [props.guid, props.size]);

  const className = useMemo(
    () => "image-" + props.size ?? "medium",
    [props.size]
  );

  return <img className={className} src={url} />;
});
