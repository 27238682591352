/** Finds the most occurences of an element in an array.
 * Usually used after mapping an object array to on of its properties
 */
export function mode(array: any[]) {
  if (array.length === 0) {
    return null;
  }
  const modeMap = Object.create(null);
  let maxEl = array[0],
    maxCount = 1;
  for (let i = 0; i < array.length; i++) {
    const el = array[i];
    if (modeMap[el] === null) {
      modeMap[el] = 1;
    } else {
      modeMap[el]++;
    }
    if (modeMap[el] > maxCount) {
      maxEl = el;
      maxCount = modeMap[el];
    }
  }
  return maxEl;
}

export const file2Base64 = (file: File): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader?.result?.toString() ?? "ERROR");
    reader.onerror = (error) => reject(error);
  });

export function currentSubDomain() {
  const host = window.location.host;
  const parts = host.split(".");
  // Subdomain gesetzt, wenn 3 abschnitte (bzw. 2 wenn auf ip/localhost)
  if (parts.length >= (process.env.NODE_ENV === "development" ? 2 : 3)) {
    return parts[0] === "www" ? undefined : parts[0].toLowerCase();
  }
  return undefined;
}

export function getSubDomainUrl(sub: string): string {
  const host = window.location.host;
  const protocol = window.location.protocol;
  const parts = host.split(".");
  // Wenn subdomain bereits gesetzt
  if (parts.length >= (process.env.NODE_ENV === "development" ? 2 : 3)) {
    return protocol + "//" + [sub, ...parts.slice(1, parts.length)].join(".");
  }
  // wenn keine subdomain vorhanden
  return protocol + "//" + [sub, ...parts].join(".");
}

export function getMainUrl(): string {
  const host = window.location.host;
  const protocol = window.location.protocol;
  const parts = host.split(".");
  // Wenn subdomain bereits gesetzt
  if (parts.length >= (process.env.NODE_ENV === "development" ? 2 : 3)) {
    return protocol + "//" + parts.slice(1, parts.length).join(".");
  }
  // wenn keine subdomain vorhanden
  return protocol + "//" + parts.join(".");
}

export function serializeObjectToQuerystring(obj: any) {
  const str = [];
  for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  }
  return str.join("&");
}
