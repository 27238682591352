import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Chruetz } from "./Chruetz";
import { Egge } from "./Egge";
import { Herz } from "./Herz";
import { Schufle } from "./Schufle";

const mapping = [
  { v: 1, c: <Herz /> },
  { v: 2, c: <Egge /> },
  { v: 3, c: <Chruetz /> },
  { v: 4, c: <Schufle /> },
  { v: 5, c: <FontAwesomeIcon icon={faArrowUp} /> },
  { v: 6, c: <FontAwesomeIcon icon={faArrowDown} /> },
];

interface Props {
  size?: any;
  variante?: number;
}
export const VarianteIcon = React.memo<Props>(function VarianteIcon({
  size = "1em",
  variante = 1,
}) {
  return <>{mapping.find((m) => m.v === variante)?.c ?? <span>?</span>}</>;
});
