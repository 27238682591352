import { AT } from "../global/actionTypes";
import { varianteToFactor } from "./gamesSelector";
import moment from "moment";

export interface SchieberSession {
  sessionId?: number;
  frzKarten: boolean;
  hand: SchieberHand[];
  pointsGoal: number;
  remarks: string;
  team1: SessionTeam;
  team2: SessionTeam;
  started?: string;
  draft?: SchieberDraft[];
  team1Result: number;
  team2Result: number;
  seasonId?: number | string;
  attachment?: string;
}

export interface SchieberDraft {
  draftId: number;
  data: string;
  sessionId: number;
  createdOn: any;
  creatorId: number;

  adminId?: number;
  adminApproval?: any;

  attachment: string;

  player1Approval?: any;
  player2Approval?: any;
  player3Approval?: any;
  player4Approval?: any;
}

export interface SessionTeam {
  teamId?: number;
  player1Id?: number;
  player2Id?: number;
}

export interface SchieberHand {
  /** client only */
  no: number;
  /** as on API */
  schieberHandId?: number;
  variante?: number;
  multiplikator: number;
  teamId?: number;
  playerDragsiId: number;
  playerGschobeId?: number;
  team1Result?: number;
  team2Result?: number;
  team1Wiis: Wiis[];
  team2Wiis: Wiis[];
}

export interface Wiis {
  betrag: number;
  playerId: number;
}

export interface GamesState {
  currentSchieberSession: SchieberSession;
  pendingChanges: boolean;
  allSchieberSessions?: SchieberSession[];
  personalSchieberDrafts?: SchieberSession[];
  currentUiIndex: number;
}
export const initialSchieberCreator = (): SchieberSession => ({
  sessionId: undefined,
  frzKarten: true,
  hand: [],
  pointsGoal: 2500,
  // eslint-disable-next-line no-restricted-syntax
  remarks: "Jasserei vom " + moment().format("D.M.yyy HH:mm"),
  team1: { teamId: undefined, player1Id: undefined, player2Id: undefined },
  team2: { teamId: undefined, player1Id: undefined, player2Id: undefined },
  team1Result: 0,
  team2Result: 0,
  seasonId: "",
});
export const initialSchieberState = {
  currentSchieberSession: initialSchieberCreator(),
  pendingChanges: false,
  currentUiIndex: 0,
};

export const changedFlagReducer = (
  state: GamesState = initialSchieberState,
  action: any
): GamesState => {
  switch (action.type) {
    // These actions are mutating the current sessionmodel, which must be saved eventually
    case AT.ADD_SCHIEBERHAND:
    case AT.REMOVE_SCHIEBERHAND:
    case AT.ADD_SCHIEBERHANDPOINTS:
    case AT.ADD_SCHIEBERWEIS:
    case AT.DO_ASAEGE:
    case AT.DO_SCHIEBE:
    case AT.DO_CHOOSEVARIANTE:
    case AT.PUT_SCHIEBERFORM:
    case AT.PUT_SCHIEBERPLAYER:
    case AT.REMOVE_SCHIEBERWEIS_TEAM1:
    case AT.REMOVE_SCHIEBERWEIS_TEAM2:
    case AT.PUT_SESSIONATTACHMENT:
      return { ...state, pendingChanges: true };
    // These actions result in having no pending changes
    case AT.PUT_SCHIEBER:
    case AT.LOCK_SCHIEBERMETADATA:
    case AT.ANNOUNCE_SCHIEBER:
    case AT.ANNOUNCE_SCHIEBERDRAFT:
    case AT.SAVE_SCHIEBERSESSION:
      return { ...state, pendingChanges: false };
    default:
      return state;
  }
};

export const gamesReducer = (
  state: GamesState = initialSchieberState,
  action: any
  // eslint-disable-next-line sonarjs/cognitive-complexity
): GamesState => {
  state = changedFlagReducer(state, action);
  switch (action.type) {
    case AT.SET_UIINDEX: {
      return {
        ...state,
        currentUiIndex: action.payload,
      };
    }
    case AT.DELETE_PERSONAL_DATA: {
      return initialSchieberState;
    }
    case AT.PUT_SESSIONATTACHMENT: {
      return {
        ...state,
        currentSchieberSession: {
          ...state.currentSchieberSession,
          attachment: action.payload,
        },
      };
    }
    case AT.ANNOUNCE_SCHIEBER: {
      return {
        ...state,
        currentSchieberSession: {
          ...state.currentSchieberSession,
          sessionId: action.payload.sessionId,
          hand: [
            {
              no: 1,
              multiplikator: 1,
              team1Result: 0,
              team2Result: 0,
              team1Wiis: [],
              team2Wiis: [],
              playerDragsiId: state.currentSchieberSession.team1.player1Id ?? 0,
            },
          ],
        },
      };
    }
    case AT.PUT_SCHIEBERS: {
      return { ...state, allSchieberSessions: action.payload };
    }
    case AT.PUT_SCHIEBER: {
      return { ...state, currentSchieberSession: action.payload };
    }
    case AT.PUT_SCHIEBERDETAILS: {
      return {
        ...state,
        allSchieberSessions: [
          ...(state?.allSchieberSessions?.map((ss) =>
            ss.sessionId === action.payload.sessionId ? action.payload : ss
          ) ?? []),
        ],
      };
    }
    case AT.PUT_SCHIEBERDRAFTS: {
      return { ...state, personalSchieberDrafts: action.payload };
    }
    case AT.DO_CHOOSEVARIANTE: {
      return {
        ...state,
        currentSchieberSession: {
          ...state.currentSchieberSession,
          hand: state.currentSchieberSession.hand.map((hand: SchieberHand) =>
            hand.no === action.payload.handNo
              ? {
                  ...hand,
                  variante: action.payload.variante,
                  multiplikator: varianteToFactor(action.payload.variante),
                }
              : hand
          ),
        },
      };
    }
    case AT.DO_SCHIEBE: {
      return {
        ...state,
        currentSchieberSession: {
          ...state.currentSchieberSession,
          hand: state.currentSchieberSession.hand.map((hand: SchieberHand) =>
            hand.no === action.payload.handNo
              ? { ...hand, playerGschobeId: action.payload.playerGschobeId }
              : hand
          ),
        },
      };
    }
    case AT.DO_ASAEGE: {
      return {
        ...state,
        currentSchieberSession: {
          ...state.currentSchieberSession,
          hand: state.currentSchieberSession.hand.map((hand: SchieberHand) =>
            hand.no === action.payload.handNo
              ? {
                  ...hand,
                  team1Result: action.payload.team1Points,
                  team2Result: action.payload.team2Points,
                }
              : hand
          ),
        },
      };
    }
    case AT.ADD_SCHIEBERWEIS: {
      if (action.payload.teamId === 2) {
        return {
          ...state,
          currentSchieberSession: {
            ...state.currentSchieberSession,
            hand: state.currentSchieberSession.hand.map((hand: SchieberHand) =>
              hand.no === action.payload.handNo
                ? {
                    ...hand,
                    team2Wiis: [
                      ...hand.team2Wiis,
                      {
                        playerId: action.payload.playerId,
                        betrag: action.payload.betrag,
                      },
                    ],
                  }
                : hand
            ),
          },
        };
      } else {
        return {
          ...state,
          currentSchieberSession: {
            ...state.currentSchieberSession,
            hand: state.currentSchieberSession.hand.map((hand: SchieberHand) =>
              hand.no === action.payload.handNo
                ? {
                    ...hand,
                    team1Wiis: [
                      ...hand.team1Wiis,
                      {
                        playerId: action.payload.playerId,
                        betrag: action.payload.betrag,
                      },
                    ],
                  }
                : hand
            ),
          },
        };
      }
    }
    case AT.REMOVE_SCHIEBERWEIS_TEAM1: {
      return {
        ...state,
        currentSchieberSession: {
          ...state.currentSchieberSession,
          hand: state.currentSchieberSession.hand.map((hand: SchieberHand) =>
            hand.no === action.payload.handNo
              ? {
                  ...hand,
                  team1Wiis: [
                    ...hand.team1Wiis.slice(0, action.payload.index),
                    ...hand.team1Wiis.slice(action.payload.index + 1),
                  ],
                }
              : hand
          ),
        },
      };
    }
    case AT.REMOVE_SCHIEBERWEIS_TEAM2: {
      return {
        ...state,
        currentSchieberSession: {
          ...state.currentSchieberSession,
          hand: state.currentSchieberSession.hand.map((hand: SchieberHand) =>
            hand.no === action.payload.handNo
              ? {
                  ...hand,
                  team2Wiis: [
                    ...hand.team2Wiis.slice(0, action.payload.index),
                    ...hand.team2Wiis.slice(action.payload.index + 1),
                  ],
                }
              : hand
          ),
        },
      };
    }
    case AT.ADD_SCHIEBERHAND: {
      const prevHand =
        state.currentSchieberSession.hand[
          state.currentSchieberSession.hand.length - 1
        ];
      let nextPlayerId = undefined;
      switch (state.currentSchieberSession.hand.length % 4) {
        case 0:
          nextPlayerId = state.currentSchieberSession.team1.player1Id;
          break;
        case 1:
          nextPlayerId = state.currentSchieberSession.team2.player1Id;
          break;
        case 2:
          nextPlayerId = state.currentSchieberSession.team1.player2Id;
          break;
        case 3:
          nextPlayerId = state.currentSchieberSession.team2.player2Id;
          break;
        default: {
          break;
        }
      }
      return {
        ...state,
        currentSchieberSession: {
          ...state.currentSchieberSession,
          hand: [
            ...state.currentSchieberSession.hand,
            {
              no: prevHand.no + 1,
              multiplikator: 1,
              playerDragsiId: nextPlayerId ?? 0,
              team1Wiis: [],
              team2Wiis: [],
            },
          ],
        },
      };
    }
    case AT.REMOVE_SCHIEBERHAND: {
      return {
        ...state,
        currentSchieberSession: {
          ...state.currentSchieberSession,
          hand:
            state.currentSchieberSession.hand.length > 1
              ? [
                  ...state.currentSchieberSession.hand.slice(
                    0,
                    state.currentSchieberSession.hand.length - 1
                  ),
                  ...state.currentSchieberSession.hand.slice(
                    state.currentSchieberSession.hand.length
                  ),
                ]
              : [...state.currentSchieberSession.hand],
        },
      };
    }
    case AT.PUT_SCHIEBERFORM: {
      return {
        ...state,
        currentUiIndex: 0,
        currentSchieberSession: {
          ...state.currentSchieberSession,
          ...action.payload,
        },
      };
    }
    case AT.PUT_SCHIEBERPLAYER: {
      // if user is already used, set the other usage to undefined
      switch (action.payload.player) {
        case 1: {
          return {
            ...state,
            currentSchieberSession: {
              ...state.currentSchieberSession,
              team1: {
                ...state.currentSchieberSession.team1,
                player1Id: action.payload.playerId,
                player2Id:
                  state.currentSchieberSession.team1.player2Id ===
                  action.payload.playerId
                    ? undefined
                    : state.currentSchieberSession.team1.player2Id,
              },
              team2: {
                ...state.currentSchieberSession.team1,
                player1Id:
                  state.currentSchieberSession.team2.player1Id ===
                  action.payload.playerId
                    ? undefined
                    : state.currentSchieberSession.team2.player1Id,
                player2Id:
                  state.currentSchieberSession.team2.player2Id ===
                  action.payload.playerId
                    ? undefined
                    : state.currentSchieberSession.team2.player2Id,
              },
            },
          };
        }
        case 2: {
          return {
            ...state,
            currentSchieberSession: {
              ...state.currentSchieberSession,
              team1: {
                ...state.currentSchieberSession.team1,
                player1Id:
                  state.currentSchieberSession.team1.player1Id ===
                  action.payload.playerId
                    ? undefined
                    : state.currentSchieberSession.team1.player1Id,
                player2Id:
                  state.currentSchieberSession.team1.player2Id ===
                  action.payload.playerId
                    ? undefined
                    : state.currentSchieberSession.team1.player2Id,
              },
              team2: {
                ...state.currentSchieberSession.team1,
                player1Id: action.payload.playerId,
                player2Id:
                  state.currentSchieberSession.team2.player2Id ===
                  action.payload.playerId
                    ? undefined
                    : state.currentSchieberSession.team2.player2Id,
              },
            },
          };
        }
        case 3: {
          return {
            ...state,
            currentSchieberSession: {
              ...state.currentSchieberSession,
              team1: {
                ...state.currentSchieberSession.team1,
                player1Id:
                  state.currentSchieberSession.team1.player1Id ===
                  action.payload.playerId
                    ? undefined
                    : state.currentSchieberSession.team1.player1Id,
                player2Id: action.payload.playerId,
              },
              team2: {
                ...state.currentSchieberSession.team1,
                player1Id:
                  state.currentSchieberSession.team2.player1Id ===
                  action.payload.playerId
                    ? undefined
                    : state.currentSchieberSession.team2.player1Id,
                player2Id:
                  state.currentSchieberSession.team2.player2Id ===
                  action.payload.playerId
                    ? undefined
                    : state.currentSchieberSession.team2.player2Id,
              },
            },
          };
        }
        case 4: {
          return {
            ...state,
            currentSchieberSession: {
              ...state.currentSchieberSession,
              team1: {
                ...state.currentSchieberSession.team1,
                player1Id:
                  state.currentSchieberSession.team1.player1Id ===
                  action.payload.playerId
                    ? undefined
                    : state.currentSchieberSession.team1.player1Id,
                player2Id:
                  state.currentSchieberSession.team1.player2Id ===
                  action.payload.playerId
                    ? undefined
                    : state.currentSchieberSession.team1.player2Id,
              },
              team2: {
                ...state.currentSchieberSession.team1,
                player1Id:
                  state.currentSchieberSession.team2.player1Id ===
                  action.payload.playerId
                    ? undefined
                    : state.currentSchieberSession.team2.player1Id,
                player2Id: action.payload.playerId,
              },
            },
          };
        }
      }
    }
  }
  return { ...state };
};
