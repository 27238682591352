import React from "react";

export const Todo = React.memo(function Todo() {
  return (
    <>
      <strong>
        Diese Änderungen, Verbesserungen und neuen Features sind geplant
      </strong>
      <hr />
      <p>Entwicklung Prio 1</p>
      <ul>
        <li>
          Support für <strong>Deutsche Karten</strong>
        </li>
      </ul>
      <p>Entwicklung Prio 2</p>
      <ul>
        <li>Player Übersicht Spalte Sessions falsch</li>
        <li>
          Club-<strong>Standardkartene</strong> (frz./deu.) und in Clubübersicht
          anzeigen
        </li>
        <li>
          Club-<strong>Erstellungsscreen</strong> mit captcha, evtl ip blocken
          gegen mehrfacherstellung
        </li>
        <li>Metadata Icons</li>
        <li>
          <strong>Swipe</strong>... beibehalten?
        </li>
        <li>
          <strong>Connection strings</strong> Management
        </li>
        <li>
          <strong>Formular Validierungen:</strong> Club erstellung,....
        </li>
      </ul>
      <p>Entwicklung Prio 3</p>
      <ul>
        <li>
          Reset <strong>Passwort</strong> (Mail...)
        </li>
        <li>
          Buttons/Tiles hover <strong>Händli-Cursor</strong>
        </li>
        <li>Drafts Table-styling verschönern</li>
        <li>
          <strong>Global Stats</strong>
        </li>
        <li>
          <strong>Gwünner</strong> markiere ide Details
        </li>
        <li>
          <strong>Statistik in Übersicht: </strong>Win/Loss
        </li>
        <li>
          <strong>Player Details: </strong> Match Ratio, Multiplikator Ratio
          (+Kuchendiagramm)
        </li>
        <li>
          Momentan wird zur <strong>Bestätigung</strong> der Erfassung nur 1
          Spieler benötigt. Dies kann auf nur 4 geändert oder konfigurierbar
          sein.
        </li>
        <li>
          <strong>Erfassung Simpel</strong> gemäss Nixxen Whitepaper (Matchblatt
          als Maske)
        </li>
        <li>Filters in Schieberoverview</li>
        <li>
          <strong>Newsticker</strong>
        </li>
        <li>
          Jasserei <strong>Messenger</strong> für: <br />
          Verbesserungsvorschläge
          <br />
          Support
          <br />
          Spendenbettlerei
          <br />
        </li>
      </ul>
      <p>Technische Todos Prio 1</p>
      <ul>
        <li>
          <strong>Bruteforce</strong>: Mehrfachaufrufe verhindern mit middleware
        </li>
        <li>
          <strong>3sec timeout</strong> request nicht aufgeben, sondern nur
          diskretere warnung
        </li>
        <li>Autofill, Save password issues</li>
        <li>
          <code>.NET 6</code> und NUGET updates
        </li>
        <li>
          <code>npm</code> updates
        </li>
        <li>
          <strong>PWA</strong>
        </li>
        <li>
          <strong>Unittests</strong>
        </li>
      </ul>
      <p>Technische Todos Prio 2</p>
      <ul>
        <li>
          Fetch_schieberdetails überholt fetch_schiebers evtl, dann werden
          Details direkt wieder überschrieben ansatz (?): reducer für
          put_schiebers soll detail-felder nicht überschreiben?
        </li>
        <li>
          Refine <strong>Granulation</strong> of React Components
        </li>
        <li>
          <strong>Performance</strong> Optimization (Memoize?)
        </li>
        <li>npm build vulnerabilities</li>
      </ul>
    </>
  );
});
