import React from "react";

export function ReleaseNotes() {
  return (
    <>
      <h2>
        Jasserei <code>v2.0</code> esch fertig!
      </h2>
      <p>Zusammenfassung der Features:</p>
      <ul>
        <li>
          Neutrale Name fürs Projekt. <strong>Jasserei</strong>
        </li>
        <li>
          <strong>Verein</strong> - Erstellung
        </li>
        <li>
          <strong>Verein</strong> - Portal
        </li>
      </ul>
      <hr />
      <p>Release Notes 2.0.0</p>
      <ul>
        <li>Neutrale Name fürs Projekt. "Jasserei"</li>
        <li>
          <strong>jasserei.ch - PROD</strong>
          <br />
          <strong>jasserei.com - TEST</strong>
          <br />
        </li>
        <li>
          Neue Entität <strong>CLUB</strong> (Verein)
        </li>
        <li>
          Subdomain, wenn vorhanden und gültig. leitet weiter auf{" "}
          <strong>Club-Seite</strong>,
          <br /> Ohne gültige Subdomain Hauptseite mit <strong>Portal</strong>.
        </li>
        <li>
          <strong>CLUB MANAGEMENT</strong>
        </li>
        <li>
          <strong>Profilfoto</strong>
        </li>
        <li>
          <code>Initialisierung</code> vom Admin Benutzer beim Verein erstellen
        </li>
        <li>
          <strong>Jasstafel-Animation</strong> als Startseite
        </li>
        <li>
          <strong>Medien</strong> (Profilefoto, Anhang, Clubmedien) in eigener
          DB Tabelle
        </li>
        <li>
          Individuelle <strong>Medien</strong> für Vereine, Adminscreen zum
          Hochladen
        </li>
        <li>
          Default <strong>Bilder</strong>
        </li>
        <li>
          Validierung der Medien beim Upload -{" "}
          <strong>Grösse und Dateiformat</strong>
        </li>
      </ul>
      <hr />
      <ul>
        <li>
          Server Side <strong>Service Layer</strong>
        </li>
        <li>
          <strong>Namespaces</strong> Durcheinander; siehe: Controllers/Dto
        </li>
        <li>
          <code>MSSQL</code> migrations
        </li>
        <li>
          <code>MSSQL</code> Seed nicht mehr benötigt
        </li>
        <li>
          <code>SQL Views </code> in Seed oder Migrations
        </li>
        <li>
          <strong>Audit/logs middleware</strong>
        </li>
        <li>
          <code>File Logging </code> mit SeriLog
        </li>
        <li>
          <code>.NET</code> publish direkt auf IIS Server
        </li>
        <li>
          <code>Let's Encrypt </code>Wildcard-Zertifikate mit win-acme
        </li>
        <li>
          <code>API Endpoints</code> haben prefix (api/v1/)
        </li>
        <li>
          <strong>Page Metadata</strong> für Search Engines etc.
        </li>
        <li>
          Validierung für Club-<strong>Erstellungsscreen</strong> und Erklärung
        </li>
      </ul>
      <hr />
      <h2>
        Jasserei <code>v1.0</code> esch fertig!
      </h2>
      <p>Zusammenfassung der Features:</p>
      <ul>
        <li>
          Detaillierte <strong>Erfassung</strong> von Schieber-Jass
        </li>
        <li>
          Aus den gesammelten Daten können detaillierte{" "}
          <strong>Statistiken</strong> und
          <strong>Ranglisten</strong> dargestellt werden.
        </li>
        <li>
          Benutzerübergreifende Erfassung mit <strong>Entwürfen</strong>
        </li>
        <li>
          <strong>History</strong> der gespielten Jass'
        </li>
        <li>
          Optimiert für <strong>Mobile </strong>devices
        </li>
        <li>Benutzeradministration</li>
      </ul>
      <hr />
      <p>Release Notes 0.9.2</p>
      <ul>
        <li>Login mit Entertaste</li>
        <li>Bug: Save-Verlauf hatten invalid Date</li>
      </ul>

      <p>Release Notes 0.9.1</p>
      <ul>
        <li>Fehler anzeigen und ausloggen, wenn die session abgelaufen ist.</li>
        <li>
          Bei den Entwürfen, ist der <strong>Verlauf der Entwürfe </strong>
          ausklappbar.
        </li>
        <li>
          Die Richtung der <strong>Pfeile </strong>beim Spieler auswählen war
          falsch rum
        </li>
        <li>
          Beim Beginn der Erfassung die Eingabe für Punkte-goal und Bemerkungen
          in <strong>Schritt 2</strong> auslagern, damit kein scrolling nötig
          ist
        </li>
        <li>
          Zurück und weiter-Button, wenn nicht in mobile. Ersatz/Ergänzung
          für&nbsp;
          <strong>Swiper</strong>
        </li>
        <li>
          Eigene <strong>Styles</strong> für den ganzen Header, damit Platz
          effizienter genutzt wird.
        </li>
        <li>
          Eigenes <strong>Button </strong>Styling anstatt material design
        </li>
        <li>
          <strong>Animationen </strong>
        </li>
        <li>Abschliessen der Erfassung: Keine buttons sondern Tiles</li>
        <li>Start der Erfassung seperat von swipe-flow.</li>
        <li>Eingabe des Punkteziels in Teppich integrieren</li>
        <li>
          Start-Funktion als header-tile und animiert, wenn Spielereingabe
          gültig ist
        </li>
        <li>
          <strong>Swipe Helper</strong>
        </li>
        <li>
          Anzeige von <strong>langen Benutzernamen</strong> abschneiden und mit
          mit <strong>...</strong> versehen
        </li>
        <li>
          Horizontales zentrieren der <strong>Jasstafel</strong>
        </li>
        <li>Style vom ansagenden/geschobenen Spieler keine Hintergrundfarbe</li>
        <li>
          Swiping deaktivieren <strong>(sehr unsensibel)</strong> in desktop und
          stattdessen static-navigator prominenter machen in desktop
        </li>
        <li>
          Swiping auf Mobile ein bischen weniger sensibel{" "}
          <strong>(20px minimum swipe)</strong>
        </li>
      </ul>

      <p>Release Notes 0.9.0</p>
      <ul>
        <li>
          <strong>Inputs von Adi und Nixxen</strong>
        </li>
        <li>
          <strong>Wiise</strong> gemäss Adi: Dialog mit zwei Schritten. Auswahl
          als Kacheln
        </li>
        <li>
          <strong>Swipe</strong> bei der Erfassung zum Navigieren
        </li>
        <li>
          <strong>Auto-Save</strong>
        </li>
        <li>
          <strong>Erfassungs- Navigation</strong> enorm vereinfacht
        </li>
        <li>Teamname beim Punktestand</li>
        <li>
          Farben-Kacheln <strong>transparent</strong>, wenn nicht ausgewählt
        </li>
        <li>
          <strong>Backdrop</strong> bei kritischen, pendenten Aktionen
        </li>
        <li>
          Nach Login gelangt man auf die Seite, auf die man ursprünglich
          navigieren wollte.
        </li>
        <li>Sequenzielles Speichern der Resultat-Eingabe</li>
        <li>
          Privates <strong>Profil</strong>
        </li>
      </ul>

      <p>Release Notes 0.8.0</p>
      <ul>
        <li>
          Input <strong>Zulauf</strong>: Neueste Hand kann entfernt werden.
        </li>
        <li>
          Man wird gefragt, welches Team gewonnen hat, wenn beide{" "}
          <strong>mehr als 2500 Punkte</strong> haben
        </li>
        <li>Default Seasonselection</li>
        <li>
          <strong>Seasons</strong> können deaktiviert werden.
        </li>
        <li>
          Dialoge in <strong>Schwizerdütsch</strong>
        </li>
        <li>
          <strong>Foto</strong> als Anhang bei der Spielerfassung
        </li>
        <li>
          Bug: Nach Funktion "Neu" war die <strong>Spielerauswahl</strong> nicht
          mehr auswählbar
        </li>
      </ul>

      <p>Release Notes 0.7.0</p>
      <ul>
        <li>
          <strong>Erfassung: </strong> Spielerauswahl und Weisen verschönert
        </li>
        <li>
          <strong>Spielerprofil </strong> inkl Stats gemäss Nixxen Whitepaper
        </li>
        <li>
          <strong>Liste der persönlichen Schieber</strong> im Spielerprofil
        </li>
        <li>
          Erfassung kann abgebrochen werden und somit werden Entwürfe verworfen.
        </li>
        <li>
          <strong>Statistikwerte</strong> in der Spielerübersicht
        </li>
        <li>
          <strong>Liniendiagramm</strong> in Spieldetails, um den Punktverlauf
          zu veranschaulichen
        </li>
        <li>
          Match und Kontermatch können per Klick angewählt werden anstatt über
          Eingabe.
        </li>
        <li>
          <strong>Offline-Tauglichkeit:</strong> Zustand der App (alle geladenen
          und frisch erfassten Daten) bleiben erhalten ("State"). Local Storage
          des Browsers wird dabei verwendet.
        </li>
        <li>
          Spielerei: <strong>Jasstafel</strong> mit Strichli in Sessiondetails
        </li>
      </ul>

      <p>Release Notes 0.6.3</p>
      <ul>
        <li>
          Login und Stammdaten bleiben im{" "}
          <strong> Browserspeicher (localstorage)</strong>erhalten:{" "}
          <code>redux-persist</code>
        </li>
        <li>
          Spielerauswahl bei Erfassung angeordnet auf
          <strong>Jassteppich</strong>
        </li>
        <li>Prompts bei kritischen Operationen</li>
      </ul>

      <p>Release Notes 0.6.2</p>
      <ul>
        <li>
          <strong>Abgekürzter</strong> Approval Prozess: nur Bestätigung des
          Erstellers benötigt fürs Erste
        </li>
        <li>
          <strong>Password</strong> Management
        </li>
      </ul>

      <p>Release Notes 0.6.1</p>
      <ul>
        <li>
          Administration:
          <strong> Benutzerverwaltung und Saisonverwaltung</strong> mit{" "}
          <code>Material Design</code>
        </li>
      </ul>

      <p>Release Notes 0.6.0</p>
      <ul>
        <li>
          <strong>Drafts</strong> der Erfassung werden bei jedem Speichern als
          <code> JSON</code> erstellt.
        </li>
        <li>
          Die Erfassung kann von anderen Benutzern<strong> übernommen</strong>{" "}
          werden
        </li>
        <li>
          <strong>Änderungen</strong> können nachvollzogen werden
        </li>
        <li>
          <strong>Spielerbestätigung</strong> anhand der neuesten Version Draft
        </li>
        <li>
          <strong>Session</strong> wird ersterstellt, wenn alle bestätigt haben.
        </li>
      </ul>

      <p>Release Notes 0.5.0</p>
      <ul>
        <li>
          <strong>Kommunikation Web-API</strong> für die Synchronisierung der
          Erfassung umgesetzt
        </li>
        <li>
          <strong>Erfassung</strong> von Schieber inkl Weis ist solide
        </li>
        <li>
          <strong>Timeline</strong> der Schiebererfassung macht Gattung
        </li>
        <li>
          <strong>Navigation</strong> der Schiebererfassung macht Gattung
        </li>
      </ul>

      <p>Release Notes 0.4.0</p>
      <ul>
        <li>
          <code>Redux Saga</code> wird verwendet für{" "}
          <strong>API Einbindung</strong>
        </li>
        <li>
          <strong>Authentifizierung</strong> über <code>Microsoft Entity</code>{" "}
          mit <code>API Token</code>
        </li>
        <li>
          Clientseitige <strong>Spielerfassung</strong> inklusive Weis und
          Zusammenfassung
        </li>
        <li>
          Rudimentäre Anzeige von Spielern, Sessions und Seasons analog alter{" "}
          <code>ng-client</code>
        </li>
      </ul>

      <p>Release Notes 0.3.0</p>
      <ul>
        <li>
          <strong>REACT</strong> Neuer Client mit <code>react/redux/saga</code>{" "}
          anstatt <code>angular</code>
        </li>
        <li>
          <strong>ASP.NET 5.0</strong> Upgrade von ASP.NET <code>CORE 3.0</code>
        </li>
        <li>
          <strong>Sync</strong> Redux State Management mit Saga bildet alles,
          insbesondere die Erfassung, ab und kann nach Bedarf
          gespeichert/synchronisiert werden. Somit ist die App
          Offline-tauglicher.
        </li>
      </ul>

      <p>Release Notes 0.2.0</p>
      <ul>
        <li>
          <strong>Redesign</strong> Jassvarianten trennen - Schieber erhält
          eigene DB Struktur
        </li>
      </ul>

      <p>Release Notes 0.1.0</p>
      <ul>
        <li>
          <strong>.NET SPA Template</strong> als Ausgangslage
        </li>
        <li>
          <strong>Aufräumen und optimieren des Templates</strong> - NSWAG, ....
        </li>
        <li>
          <strong>DB Setup</strong> - Datenbankstruktur. Entitäten: Season,
          Player, Session, Team, TeamPlayer, Game, PlayerResult, Weis
        </li>
        <li>
          <strong>Spielerüberblick</strong> Überblick über alle registrierten
          Spieler. Diese Ansicht soll später für Erstellung und Verwaltung der
          Mitglieder dienen.
        </li>
        <li>
          <strong>Spielerprofil</strong>
        </li>
        <li>
          <strong>Session Überblick</strong> Überblick über alle Sessions, egal
          von welchem Spieler, Season, und in welchem Stadium
        </li>
        <li>
          <strong>Matchblatt</strong> - Herzstück der App
        </li>
        <li>
          <strong>ASP.NET CORE 3.0</strong> Server Runtime von{" "}
          <code>Microsoft</code> - Platformunabhängig lauffähig
        </li>
        <li>
          <strong>SQLite</strong> - File basierte Datenbank
        </li>
        <li>
          <strong>ng8</strong> Single Page Web Application mit{" "}
          <code>Google Angular 8</code>
        </li>
        <li>
          <strong>Bootstrap</strong> Stylesheets Framework von{" "}
          <code>Twitter</code>
        </li>
        <li>
          <strong>GIT/DevOps</strong> Sourceverwaltung in{" "}
          <code>Azure DevOps</code> über GIT{" "}
        </li>
      </ul>
    </>
  );
}
