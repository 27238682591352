import React, { useCallback, useState } from "react";
import { faSave, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Paper, TextField, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { User } from "../../types/types";
import {
  deleteAuthData,
  deletePersonalData,
  fetchUpdateUserDetails,
  putCurrentUser,
} from "../../user/userActionCreators";
import { selectUser } from "../../user/userSelector";
import { useJassereiFormStyles } from "../Common/useJassereiFormStyles";
import { ChangePassword } from "./ChangePassword";
import { UploadImage } from "../Common/UploadImage";

export function UserDetails() {
  const storeUser = useSelector(selectUser);

  return storeUser ? (
    <UserDetailsInternal user={storeUser} />
  ) : (
    <div>Heilandsack...</div>
  );
}

interface Props {
  user: User;
}
export function UserDetailsInternal(props: Props) {
  const classes = useJassereiFormStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [saved, setSaved] = useState(true);
  const [user, setUser] = useState({ ...props.user });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
    setSaved(false);
  };
  const save = useCallback(
    (e: any) => {
      dispatch(fetchUpdateUserDetails(user));
      setSaved(true);
    },
    [dispatch, user]
  );
  const logout = useCallback(() => {
    dispatch(deleteAuthData);
    dispatch(deletePersonalData);
    history.push("login");
  }, [dispatch, history]);

  const setAvatar = useCallback(
    (guid) => dispatch(putCurrentUser({ ...user, avatar: guid })),
    [dispatch, user]
  );

  return (
    <>
      <Button
        className={classes.root}
        variant="contained"
        color="secondary"
        onClick={logout}
        startIcon={<FontAwesomeIcon icon={faSignOutAlt} />}
      >
        Abmelde
      </Button>
      <Paper elevation={3} className={"matchblatt"}>
        <Typography variant="h5" component="h2">
          Profilbild
        </Typography>
        <UploadImage
          callBackFn={setAvatar}
          additionalParams={[
            { key: "AssignedId", value: String(user?.userId) },
          ]}
          url={"users/avatar"}
          mediaGuid={props.user?.avatar}
        >
          Profilbild wählen
        </UploadImage>
      </Paper>
      <Paper elevation={3} className={"matchblatt"}>
        <Typography variant="h5" component="h2">
          Profil
        </Typography>
        <form noValidate autoComplete="off" className={classes.root}>
          <TextField
            id="firstName"
            name="firstName"
            label="Vorname"
            variant="outlined"
            onChange={handleChange}
            value={user.firstName}
          />
          <TextField
            id="lastName"
            name="lastName"
            label="Nachname"
            variant="outlined"
            onChange={handleChange}
            value={user.lastName}
          />
          <TextField
            id="nickname"
            name="nickname"
            label="Spitzname"
            variant="outlined"
            onChange={handleChange}
            value={user.nickname}
          />
        </form>
        <Button
          className={classes.root}
          variant="contained"
          color="primary"
          onClick={save}
          disabled={saved}
          startIcon={<FontAwesomeIcon icon={faSave} />}
        >
          Speichere
        </Button>
      </Paper>
      <Paper elevation={3} className={"matchblatt"}>
        <ChangePassword />
      </Paper>
    </>
  );
}
