import React, { useCallback } from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { putCurrentSchieber } from "../../../games/gamesActionCreators";
import { initialSchieberCreator } from "../../../games/gamesReducer";
import { deleteSession } from "../../../games/registerGameActionCreators";
import { PromptedButton } from "../../Common/PromptedButton";

export function CancelSchieberRegister(props: any) {
  const dispatch = useDispatch();
  const history = useHistory();

  const reset = useCallback(() => {
    dispatch(deleteSession());
    dispatch(putCurrentSchieber(initialSchieberCreator()));
    history.push("/drafts");
  }, [history, dispatch]);

  return (
    <PromptedButton
      color="secondary"
      okFn={reset}
      startIcon={<FontAwesomeIcon icon={faTrash} />}
      promptText={"Wotsch de Entwurf lösche ond e neui Erfassig afange?"}
      needsPrompt={true}
    >
      {props.children}
    </PromptedButton>
  );
}
