import React from "react";
import { RegisterSchieberMatch } from "./RegisterSchieberMatch";

import "./erfassen.scss";

interface Props {
  onInput: (asaeger: number, gegner: number) => any;
  amount: number;
  isMatch?: boolean;
  isKonterMatch?: boolean;
}
export function RegisterSchieberHandAsaegeAmount(props: Props) {
  const onPointsChanged = (e: any) => {
    const val = Number(e.target.value);
    if (val >= 0 && val <= 157) {
      props.onInput(val, 157 - val);
    }
  };
  const onClick = (e: any) => e.target.select();

  return (
    <>
      <RegisterSchieberMatch {...props} />
      <div className="puenkt">
        <input
          type="number"
          value={props.amount}
          onChange={onPointsChanged}
          onBlur={onPointsChanged}
          onClick={onClick}
          max={157}
          min={0}
          step={1}
        ></input>
      </div>
    </>
  );
}
